import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { VideoComponent } from './video/video.component';
import { HomeComponent } from './home/home.component';

const appRoutes: Routes = [
    { path: 'video',  component: VideoComponent },
    { path: '', component: HomeComponent },
];

@NgModule({
    imports: [ RouterModule.forRoot(appRoutes) ],
    exports: [ RouterModule ]
})

export class AppRoutingModule { }