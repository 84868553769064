import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { HeadbarComponent } from './shared/headbar/headbar.component';

import { VideoComponent } from './video/video.component';
import { HomeComponent } from '../app/home/home.component';

@NgModule({
  declarations: [
    AppComponent,
    VideoComponent,
    HeadbarComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
